import { Router } from '@angular/router';
import { environment as env } from '@environments/environment';

export class Helpers {
  static googleFonts = [
    'Gabarito',
    'Open Sans',
    'Roboto',
    'Lato',
    'Montserrat',
    'Raleway',
    'Ubuntu',
    'Noto Sans',
    'Poppins',
    'Source Sans Pro',
    'Playfair Display',
    'Merriweather',
    'Oswald',
    'Fira Sans',
    'Quicksand',
    'Pacifico',
    'Muli',
    'Dosis',
    'Hind',
    'Archivo',
    'Cabin',
    'Comfortaa',
    'Baloo',
    'PT Sans',
    'Sriracha',
    'Karla',
    'Titillium Web',
    'Exo',
    'Yantramanav',
    'Nunito',
    'Anton',
    'Arvo',
  ];

  static hostedFonts = ['Euclid Circular B', 'Rift', 'Neoneon', 'Neue Plak'];

  static localFonts = ['Begum', 'Mostra Nuova'];

  static languages = ['de', 'en'];

  static getColorLuminance(hexColor: string): number {
    const rgb = [
      parseInt(hexColor.substring(1, 3), 16),
      parseInt(hexColor.substring(3, 5), 16),
      parseInt(hexColor.substring(5), 16),
    ];
    return (
      (0.2126 * rgb[0]) / 255 +
      (0.7152 * rgb[1]) / 255 +
      (0.0722 * rgb[2]) / 255
    );
  }

  static getColorWithOpacity(hexColor: string, opacity: number) {
    const rgb = [
      parseInt(hexColor.substring(1, 3), 16),
      parseInt(hexColor.substring(3, 5), 16),
      parseInt(hexColor.substring(5), 16),
    ];

    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
  }

  static getTextColorForBg(hexColor?: string) {
    if (!hexColor) {
      return 'white';
    }

    if (hexColor.length == 7) {
      const luminance = Helpers.getColorLuminance(hexColor);
      return luminance > 0.526 ? '#282828' : 'white';
    }
    return '#282828';
  }

  static fetchEventColors(color: string) {
    const textColor = Helpers.getTextColorForBg(color);

    const colorLuminance = Helpers.getColorLuminance(color);
    let lightBackgroundColor: string;
    let lighterBackgroundColor: string;
    if (colorLuminance > 0.9) {
      lightBackgroundColor = Helpers.adjustBrightness(color, -0.2);
      lighterBackgroundColor = Helpers.adjustBrightness(color, -0.8);
    } else if (colorLuminance > 0.75) {
      lightBackgroundColor = Helpers.adjustBrightness(color, 0.9);
      lighterBackgroundColor = Helpers.adjustBrightness(color, 0.95);
    } else {
      lightBackgroundColor = Helpers.adjustBrightness(color, 0.75);
      lighterBackgroundColor = Helpers.adjustBrightness(color, 0.85);
    }

    const lightTextColor = Helpers.getTextColorForBg(lightBackgroundColor);

    return {
      textColor: textColor === 'white' ? textColor : '#f3f3f3',
      lightBackgroundColor,
      lightTextColor,
      lighterBackgroundColor,
    };
  }

  static setEventLinkBioTheme(
    backgroundColor?: string,
    titleColor?: string,
    bodyColor?: string,
  ) {
    if (backgroundColor) {
      document.body.style.setProperty(
        '--event-link-bio-background-color',
        backgroundColor,
      );
    }

    if (titleColor) {
      document.body.style.setProperty(
        '--event-link-bio-title-color',
        titleColor,
      );
    }

    if (bodyColor) {
      document.body.style.setProperty('--event-link-bio-body-color', bodyColor);
    }
  }
  static setEventTheme(
    primaryColor?: string,
    bodyColor?: string,
    backgroundColor?: string,
    signalColor?: string,
  ) {
    if (primaryColor && bodyColor) {
      const eventColors = Helpers.fetchEventColors(primaryColor);
      const primaryTextColor = Helpers.getTextColorForBg(primaryColor);

      document.documentElement.style.setProperty(
        '--event-theme-primary-color',
        primaryColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-secondary-color',
        eventColors.lightBackgroundColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-ternary-color',
        eventColors.lighterBackgroundColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-accent-color',
        eventColors.lightBackgroundColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-action-color',
        eventColors.lighterBackgroundColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-primary-text-color',
        primaryTextColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-primary-text-opposite-color',
        primaryTextColor === 'white' ? '#282828' : 'white',
      );
      document.documentElement.style.setProperty(
        '--event-theme-accent-text-color',
        eventColors.lightTextColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-brand-color',
        primaryColor,
      );
      document.documentElement.style.setProperty(
        '--normal-dialog-event-background-color',
        eventColors.lighterBackgroundColor,
      );

      document.documentElement.style.setProperty(
        '--mat-form-field-background-color',
        eventColors.textColor,
      );
      document.documentElement.style.setProperty(
        '--mat-form-field-border-color',
        'transparent',
      );
      document.documentElement.style.setProperty(
        '--mat-form-field-focused-border-color',
        primaryColor,
      );
      document.documentElement.style.setProperty(
        '--mat-form-field-input-color',
        primaryColor,
      );
      document.documentElement.style.setProperty(
        '--mat-form-field-label-color',
        eventColors.lightBackgroundColor,
      );
      document.documentElement.style.setProperty(
        '--mat-form-field-label-focused-color',
        primaryColor,
      );
      const primaryWithOpacity = Helpers.getColorWithOpacity(primaryColor, 0.5);
      document.documentElement.style.setProperty(
        '--event-theme-primary-color-opacity',
        primaryWithOpacity,
      );

      const colorLuminance = Helpers.getColorLuminance(primaryColor);
      if (colorLuminance > 0.9) {
        const bodyColorLuminance = Helpers.getColorLuminance(bodyColor);
        if (bodyColorLuminance > 0.9) {
          document.documentElement.style.setProperty(
            '--event-theme-rsvp-primary-color',
            '#473bff',
          );
          document.documentElement.style.setProperty(
            '--event-theme-rsvp-primary-text-color',
            Helpers.getTextColorForBg('#473bff'),
          );
        } else {
          document.documentElement.style.setProperty(
            '--event-theme-rsvp-primary-color',
            bodyColor,
          );
          document.documentElement.style.setProperty(
            '--event-theme-rsvp-primary-text-color',
            Helpers.getTextColorForBg(bodyColor),
          );
        }
      } else {
        document.documentElement.style.setProperty(
          '--event-theme-rsvp-primary-color',
          primaryColor,
        );
        document.documentElement.style.setProperty(
          '--event-theme-rsvp-primary-text-color',
          Helpers.getTextColorForBg(primaryColor),
        );
      }
      document.documentElement.style.setProperty(
        '--event-theme-body-color',
        bodyColor,
      );
    } else if (primaryColor) {
      const eventColors = Helpers.fetchEventColors(primaryColor);
      const primaryTextColor = Helpers.getTextColorForBg(primaryColor);

      document.documentElement.style.setProperty(
        '--event-theme-primary-color',
        primaryColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-secondary-color',
        eventColors.lightBackgroundColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-ternary-color',
        eventColors.lighterBackgroundColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-accent-color',
        eventColors.lightBackgroundColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-action-color',
        eventColors.lighterBackgroundColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-primary-text-color',
        primaryTextColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-primary-text-opposite-color',
        primaryTextColor === 'white' ? '#282828' : 'white',
      );
      document.documentElement.style.setProperty(
        '--event-theme-accent-text-color',
        eventColors.lightTextColor,
      );
      document.documentElement.style.setProperty(
        '--event-theme-brand-color',
        primaryColor,
      );
      document.documentElement.style.setProperty(
        '--normal-dialog-event-background-color',
        eventColors.lighterBackgroundColor,
      );

      document.documentElement.style.setProperty(
        '--mat-form-field-background-color',
        eventColors.textColor,
      );
      document.documentElement.style.setProperty(
        '--mat-form-field-border-color',
        'transparent',
      );
      document.documentElement.style.setProperty(
        '--mat-form-field-focused-border-color',
        primaryColor,
      );
      document.documentElement.style.setProperty(
        '--mat-form-field-input-color',
        primaryColor,
      );
      document.documentElement.style.setProperty(
        '--mat-form-field-label-color',
        eventColors.lightBackgroundColor,
      );
      document.documentElement.style.setProperty(
        '--mat-form-field-label-focused-color',
        primaryColor,
      );
      const primaryWithOpacity = Helpers.getColorWithOpacity(primaryColor, 0.5);
      document.documentElement.style.setProperty(
        '--event-theme-primary-color-opacity',
        primaryWithOpacity,
      );
    } else if (bodyColor) {
      document.documentElement.style.setProperty(
        '--event-theme-body-color',
        bodyColor,
      );
    }

    if (backgroundColor && backgroundColor !== '') {
      document.documentElement.style.setProperty(
        '--event-theme-background-color',
        backgroundColor,
      );
    }
    if (signalColor) {
      document.documentElement.style.setProperty(
        '--event-theme-signal-color',
        signalColor,
      );
    }
  }

  static resetEventTheme() {
    document.documentElement.style.setProperty(
      '--event-theme-primary-color',
      '#282828',
    );
    document.documentElement.style.setProperty(
      '--event-theme-background-color',
      '#ffffff',
    );
    document.documentElement.style.setProperty(
      '--event-theme-secondary-color',
      '#f3f3f3',
    );
    document.documentElement.style.setProperty(
      '--event-theme-ternary-color',
      '#f3f3f3',
    );
    document.documentElement.style.setProperty(
      '--event-theme-accent-color',
      '#ffffff',
    );
    document.documentElement.style.setProperty(
      '--event-theme-action-color',
      '#473bff',
    );
    document.documentElement.style.setProperty(
      '--event-theme-primary-text-color',
      '#282828',
    );
    document.documentElement.style.setProperty(
      '--event-theme-accent-text-color',
      '#282828',
    );
    document.documentElement.style.setProperty(
      '--event-theme-brand-color',
      '#473bff',
    );
    document.documentElement.style.setProperty(
      '--event-theme-signal-color',
      '#282828',
    );

    document.documentElement.style.setProperty(
      '--normal-dialog-event-background-color',
      '#f3f3f3',
    );

    document.documentElement.style.setProperty(
      '--mat-form-field-background-color',
      '#f3f3f3',
    );
    document.documentElement.style.setProperty(
      '--mat-form-field-border-color',
      'transparent',
    );
    document.documentElement.style.setProperty(
      '--mat-form-field-focused-border-color',
      '#473bff',
    );
    document.documentElement.style.setProperty(
      '--mat-form-field-input-color',
      '#282828',
    );
    document.documentElement.style.setProperty(
      '--mat-form-field-label-color',
      'rgba(0, 0, 0, 0.6)',
    );
    document.documentElement.style.setProperty(
      '--mat-form-field-label-focused-color',
      '#473bff',
    );
  }

  static setEventFonts(
    primaryFont?: string,
    bodyFont?: string,
    bodyFontWeight?: number,
  ) {
    if (primaryFont) {
      document.documentElement.style.setProperty(
        '--primary-font-family',
        `"${primaryFont}"`,
      );
    }

    if (bodyFont) {
      document.documentElement.style.setProperty(
        '--body-font-family',
        `"${bodyFont}"`,
      );
    }

    if (bodyFontWeight) {
      document.documentElement.style.setProperty(
        '--body-font-weight',
        `${bodyFontWeight}`,
      );
    }
  }

  static setCampaignTheme(
    primaryColor: string,
    actionColor: string,
    bgColor: string,
    brandColor: string,
    accentColor: string,
  ) {
    const eventColors = Helpers.fetchEventColors(primaryColor);

    document.documentElement.style.setProperty(
      '--event-theme-primary-color',
      primaryColor,
    );
    document.documentElement.style.setProperty(
      '--event-theme-action-color',
      actionColor,
    );
    document.documentElement.style.setProperty(
      '--event-theme-ternary-color',
      primaryColor,
    );

    document.documentElement.style.setProperty(
      '--event-theme-background-color',
      bgColor,
    );

    document.documentElement.style.setProperty(
      '--event-theme-front',
      primaryColor,
    );

    document.documentElement.style.setProperty('--event-theme-back', '#272727');

    document.documentElement.style.setProperty(
      '--event-theme-brand-color',
      brandColor,
    );

    document.documentElement.style.setProperty(
      '--mat-form-field-background-color',
      primaryColor,
    );
    document.documentElement.style.setProperty(
      '--event-theme-primary-text-color',
      primaryColor,
    );
    document.documentElement.style.setProperty(
      '--event-theme-secondary-color',
      'black',
    );
    document.documentElement.style.setProperty(
      '--mat-form-field-border-color',
      'transparent',
    );
    document.documentElement.style.setProperty(
      '--mat-form-field-focused-border-color',
      brandColor,
    );
    document.documentElement.style.setProperty(
      '--mat-form-field-input-color',
      Helpers.getTextColorForBg(primaryColor),
    );
    document.documentElement.style.setProperty(
      '--mat-form-field-label-color',
      eventColors.lightBackgroundColor,
    );
    document.documentElement.style.setProperty(
      '--mat-form-field-label-focused-color',
      eventColors.lightBackgroundColor,
    );
    document.documentElement.style.setProperty(
      '--event-theme-accent-color',
      accentColor,
    );
  }

  static setTheme() {
    const userTheme = localStorage.getItem('userTheme');
    if (userTheme) {
      if (userTheme === 'dark') {
        document.body.classList.add('dark-mode');
      } else if (userTheme === 'light') {
        document.body.classList.remove('dark-mode');
      } else {
        // system
        const darkModeOn =
          window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches;

        if (darkModeOn) {
          document.body.classList.add('dark-mode');
        } else {
          document.body.classList.remove('dark-mode');
        }
      }
    } else {
      document.body.classList.remove('dark-mode');
    }
  }

  static getTheme(): string {
    return document.body.classList.contains('dark-mode') ? 'dark' : 'light';
  }

  static hex2(c: number) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;

    var s = c.toString(16);
    if (s.length < 2) s = '0' + s;

    return s;
  }

  static color(r: number, g: number, b: number) {
    return '#' + Helpers.hex2(r) + Helpers.hex2(g) + Helpers.hex2(b);
  }

  static adjustBrightness(col: string, light: number) {
    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);

    if (light < 0) {
      r = (1 + light) * r;
      g = (1 + light) * g;
      b = (1 + light) * b;
    } else {
      r = (1 - light) * r + light * 255;
      g = (1 - light) * g + light * 255;
      b = (1 - light) * b + light * 255;
    }

    return Helpers.color(r, g, b);
  }

  static isImageMimeType(mimeType: string): boolean {
    return mimeType.startsWith('image/');
  }

  static isVideoMimeType(mimeType: string): boolean {
    return mimeType.startsWith('video/');
  }

  static reSetupPlatform() {
    if (window && window.location && window.location.hostname) {
      const favIcon: any = document.querySelector('#favIcon');

      if (window.location.hostname === 'app.partypage.ai') {
        env.website = 'https://app.partypage.ai';
        env.assets = 'https://app.partypage.ai';
        env.logoAsset = './assets/logo-partypage.png';
        env.iconAsset = './assets/icon-partypage.svg';
        env.platform = 'partypage.ai';
        env.platformShort = 'partypage';
        if (!localStorage.getItem('userTheme')) {
          localStorage.setItem('userTheme', 'dark');
        }
        favIcon.href = 'favicon-partypage.ico';
      } else {
        env.website = 'https://app.eventpage.ai';
        env.assets = 'https://app.eventpage.ai';
        env.logoAsset = './assets/logo.svg';
        env.iconAsset = './assets/icon.svg';
        env.platform = 'eventpage.ai';
        env.platformShort = 'eventpage';
        if (!localStorage.getItem('userTheme')) {
          localStorage.setItem('userTheme', 'light');
        }
        favIcon.href = 'favicon.ico';
      }
    }
  }

  static normalizeGmail(email: string): string {
    const [username, domain] = email.split('@');
    if (domain && domain.toLowerCase() === 'gmail.com') {
      const normalizedUsername = username.replace(/\./g, '');
      return `${normalizedUsername}@${domain}`;
    }
    return email;
  }

  static paginateArray<T>(
    array: T[],
    pageNumber: number,
    pageSize: number,
  ): T[] {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return array.slice(startIndex, endIndex);
  }

  static convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(';base64,');
    // hold the content type
    const imageType = parts[0].split(':')[1];
    // decode base64 string
    const decodedData = window.atob(parts[1]);
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }

  static navigateNewWindow(router: Router, commands: any[]) {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const path = router.serializeUrl(router.createUrlTree(commands));
    window.open(baseUrl + path, '_blank');
  }

  static isValidEmail(email: string): boolean {
    // Regular expression for validating an Email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  static isSelectionInsideRow(rowElement: HTMLElement): boolean {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      return rowElement.contains(range.commonAncestorContainer);
    }
    return false;
  }

  static encodeUrl(url: string): string {
    const lastSlashIndex = url.lastIndexOf('/');

    if (lastSlashIndex === -1) {
      return encodeURI(url);
    }

    const baseUrl = url.substring(0, lastSlashIndex + 1);
    const fileName = url.substring(lastSlashIndex + 1);

    const encodedFileName = encodeURIComponent(fileName)
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29');

    return encodeURI(baseUrl) + encodedFileName;
  }
}
